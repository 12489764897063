import React, { memo, useCallback } from "react";
import useStyles from "./styles";
import Rating from "@material-ui/lab/Rating";
import { useController } from "react-hook-form";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

const RatingField = memo((props) => {
  const classes = useStyles();
  const { control, defaultValue, name, ...others } = props;
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || 0,
  });

  const handleChange = useCallback((e,value) => {
    onChange({ target: { value } });
  }, [onChange]);


  return (
    <Rating
      {...others}
      {...inputProps}
      control={control}
      classes={{ root: classes.rating }}
      name={name}
      onChange={handleChange}
      icon={<StarIcon color='primary' fontSize='inherit' />}
      emptyIcon={<StarBorderIcon color='primary' fontSize='inherit' />}
      inputRef={ref}
    />
  );
});

export default RatingField;
