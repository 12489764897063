import Enviroment from '../entities/envirement';
import ApiNotOk from '../entities/errors/apiNotOk';
import ApiError from '../entities/errors/apiError';
import { getTokenSync, emmitLogout } from '../hooks/useAuthenticate';

export default class AbstractApiService {
  static base = `${Enviroment.get()}`;

  static async fetch(url, params = {}, isFormData = false) {
    const headers =  {
      'x-access-token': getTokenSync(),
      'Content-Type': 'application/json',
      ...params.headers,
      'X-Requested-With': 'PUBLIC'
    }
    
    if(isFormData && headers['Content-Type']) {
      delete headers['Content-Type'];
    }

    const response = await fetch(`${this.base}${url}`, {
      ...params,
      headers
    });

    if (!response.ok) {
      ApiNotOk.throwError();
    }

    const result = await response.json();

    if (result.error) {
      if (result.message.includes('Token Wrogn')) {
        emmitLogout();
      }
      ApiError.throwError(result.message);
    }

    return result.data;
  }
}
