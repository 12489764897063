import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
    right: 0,
    left: 0,
    textAlign: 'center',
    position: 'absolute',
    textTransform: 'uppercase'
  }
}));

export default useStyles;