import AbstractApiService from '../abstractApiService';
import queryString from 'query-string';

export default class LocaleService extends AbstractApiService {
  static basepath = '/locale';

  static async check({ lat, lng }) {
    const query = queryString.stringify({ lat, lng });
    const response = await this.fetch(`${this.basepath}/check?${query}`); 
    return response;
  }
  
  static async list({ orderBy }) {
    const query = queryString.stringify({ orderBy });
    const response = await this.fetch(`${this.basepath}/?${query}`); 
    return response?.result;
  }
  
}
