import MuiTextField from "@material-ui/core/TextField";
import React, { memo, useCallback } from "react";
import { useController } from "react-hook-form";
import masks from "utils/masks";
import useMobileDetect from 'use-mobile-detect-hook';


const TextField = memo((props) => {
  const detectMobile = useMobileDetect();
  const isIOS = detectMobile.isIos();
  const { control, defaultValue, name, mask, error, helperText, ...others } = props;
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState
  } = useController({
    name,
    control,
    defaultValue: defaultValue || ''
  });

  const handleChange = useCallback((e) => {
    e.preventDefault();
    const { value } = e.target;
    const v = mask ? masks[mask]?.clean(value) : value;
    onChange(v);
  }, [mask, onChange]);

  return (
    <MuiTextField
      {...others}
      {...inputProps}
      variant={isIOS ? "standard" : "outlined"}
      margin="normal"
      inputRef={ref}
      onChange={handleChange}
      value={mask ? masks[mask]?.masked(value) : value}
      error={fieldState.invalid || error}
      helperText={fieldState?.error?.message || helperText}
    />
  );
})

export default TextField;
