import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

export function Heading({ children, variant = "h6", color = "#fff", ...others }) {
  const classes = useStyles({ color });

  return (
    <Box
      className={classes.header}
      display="flex"
      alignItems="center"
      flexDirection="column"
      color={color}
    >
      <Typography component="h2" variant={variant} color="inherit" {...others}>
        {children}
      </Typography>
      <Divider className={classes.line} />
    </Box>
  );
}
