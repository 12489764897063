import React from "react";
import Drawer from "@material-ui/core/Drawer";
import useStyles from "./styles";

function DrawerBottom({ children, open, onToggleDrawer }) {
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onToggleDrawer(open);
  };


  return (
    <Drawer
      anchor="bottom"
      open={open}
      classes={{ paper: classes.root }}
      onClose={toggleDrawer(false)}
    >
      <div>
        {children}
      </div>
    </Drawer>
  );
}

export default DrawerBottom;
