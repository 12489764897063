import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  avatar: {
    width: '100vw',
    height: theme.spacing(25),
    backgroundColor: theme.palette.secondary.main,
    objectPosition: 'top',
  },
  avatarImg: {
    objectPosition: 'top'
  },
  avatarContainer: {
    position: 'relative'
  },
  addBtn: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.primary.light
  },
  homeBtn: {
    marginTop: theme.spacing(2)
  },
  inputAdornment: {
    marginBottom: theme.spacing(1)
  },
  chip: {
    padding: theme.spacing(1)/2,
    backgroundColor: '#D2FEE6'
  }
}));

export default useStyles;