const typography =  {
  fontFamily: [
    'Segoe UI',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
  ].join(','),
}

export default typography;