import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  progressChart: {
    position: 'relative',
    display: "inline-flex"
  },
  bottom: {
    color: '#BDFFDB',
  },
  top: {
    position: 'absolute',
    left: 0,
    color: ({ color }) => color || 'primary'
  },
  circle: {
    strokeLinecap: 'round',
  },
  value: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: ({ color }) => color || 'primary'
  }
}));

export default useStyles;