import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
    maxHeight: '55vh',
    overflowY: 'auto'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  card: {
    height: '100%',
    minHeight: 106,
    outlineWidth: 1,
    outlineStyle: 'solid',
    outlineColor: theme.palette.text.secondary,
  },
  cardAction: {
    height: '100%',
    padding: theme.spacing(1)
  }
}));

export default useStyles;
