import React, { memo, useCallback } from "react";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import useStyles from "./styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { navigate } from "@reach/router";
import { columns } from "..";

const ListItem = memo((props) => {
  const { row } = props;
  const classes = useStyles();

  const handleServiceDetails = useCallback(async () => {
    await navigate(`/s/solicitacao/${row._id}`);
  }, [row._id]);

  return (
    <TableRow hover>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format ? column.format(value) : value}
          </TableCell>
        );
      })}
      <TableCell align="right" className={classes.sticky}>
        <IconButton size="small" className={classes.icon} onClick={handleServiceDetails}>
          <VisibilityIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
})

export default ListItem;
