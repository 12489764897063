import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0
  },
  btn: {
    textAlign: 'center'
  }
}));

export default useStyles;