import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { navigate } from "@reach/router";
import ImageField from "components/Fields/ImageField";
import { formatLocationLabel } from 'utils/formatLocationLabel';

const DetailsService = (props) => {
  const { store } = props;
  const classes = useStyles();

  const handleNavigate = (path) => async () => {
    await navigate(path);
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      className={classes.root}
    >
      <Grid item xs={12}>
        <Typography color="secondary" variant="h3" align="center">
          <CheckCircleOutlinedIcon fontSize="inherit" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="secondary" variant="h6" align="center">
          Serviço foi solicitado com sucesso!
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="primary" variant="h6">
          Detalhes
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          readOnly
          disabled
          InputLabelProps={{ shrink: true }}
          label="Número do protocolo"
          value={store.code}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          readOnly
          disabled
          InputLabelProps={{ shrink: true }}
          label="Horário da solicitação"
          value={store.createdAt && new Date(store.createdAt).toLocaleString("pt-BR")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          readOnly
          disabled
          InputLabelProps={{ shrink: true }}
          label="Inserir localização *"
          value={formatLocationLabel(store?.location?.address)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          readOnly
          disabled
          InputLabelProps={{ shrink: true }}
          label="Tipo de serviço *"
          value={store?.service?.name}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Descrição"
          required
          multiline
          disabled
          InputLabelProps={{ shrink: true }}
          value={store?.description}
          minRows={5}
          maxRows={5}
        />
      </Grid>
      <Grid item xs={12}>
        <ImageField
          label="Fotos"
          name="images"
          maxLength={store?.images?.length}
          value={store?.images}
          disabled
          accept="image/*"
        />
      </Grid>
      <Grid item xs={8}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNavigate("/s/inicio")}
          fullWidth
        >
          Voltar ao início
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Button
          color="primary"
          variant="text"
          fullWidth
          onClick={handleNavigate("/s/minhas-solicitacoes")}
        >
          Ver minhas solicitações
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailsService;
