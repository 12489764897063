import { Box, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import { HalfwayDialog } from "../DinamicHeightDialog";
import { Heading } from "../Heading";
import ItemDescription from "../ItemDescription";
import ImageField from "components/Fields/ImageField";

function HistoryDetails({ isOpen, handleClose, history }) {
  const themes = useTheme();
  const pictures = useMemo(
    () => history.attachments || [],
    [history.attachments]
  );

  return (
    <HalfwayDialog open={isOpen} handleClose={handleClose}>
      <Box
        width="100%"
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Heading color={themes.palette.primary.dark}>
          Detalhes do histórico
        </Heading>
      </Box>
      <Box padding={1}>
        <ItemDescription {...history} />
        {Boolean(pictures?.length) && (
          <ImageField
            label="Fotos"
            name="images"
            maxLength={pictures?.length}
            value={pictures}
            disabled
            accept="image/*"
          />
        )}
      </Box>
    </HalfwayDialog>
  );
}

export default HistoryDetails;
