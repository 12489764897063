import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import useStyles from "./styles";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import { useCallback } from "react";

const GridService = ({ data, value, dispatch }) => {
  const classes = useStyles();

  const handleSelect = useCallback(
    (service) => () => {
      if (service._id === value) {
        dispatch({ service: undefined  });
      } else {
        dispatch({ service });
      }
    },
    [dispatch, value]
  );

  return (
    <Grid container className={classes.root}>
      {data.map((item) => (
        <Grid item xs={12} key={`list-item-${item._id}`}>
          <Card
            square
            elevation={0}
            className={clsx(classes.card, { [classes.selected]: value === item._id })}
          >
           <CardActionArea className={classes.cardAction} onClick={handleSelect(item)} >
              <Typography align="center">
                <Icon>{item.icon}</Icon>
              </Typography>
              <Typography align="center">{item.name}</Typography>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridService;
