export const enHistoryTypes = {
  OPENING: "OPENING",
  UPDATE: "UPDATE",
  MESSAGE: "MESSAGE",
  PUBLIC_STATUS_UPDATE: "PUBLIC_STATUS_UPDATE",
  PRIVATE_STATUS_UPDATE: "PRIVATE_STATUS_UPDATE",
  SERVICE_TYPE_UPDATE: "SERVICE_TYPE_UPDATE",
  SECTOR_TRANSFER_REQUEST: "SECTOR_TRANSFER_REQUEST",
  SECTOR_TRANSFER_ACCEPTED: "SECTOR_TRANSFER_ACCEPTED",
  SECTOR_TRANSFER_DENIED: "SECTOR_TRANSFER_DENIED",
  PROVIDER_TRANSFER_REQUEST: "PROVIDER_TRANSFER_REQUEST",
  PROVIDER_TRANSFER_ACCEPTED: "PROVIDER_TRANSFER_ACCEPTED",
  PROVIDER_TRANSFER_DENIED: "PROVIDER_TRANSFER_DENIED",
  CLOSING: "CLOSING",
  RATED: "RATED",
  REOPENING: "REOPENING",
};

export const enHistoryTypeLabel = {
  [enHistoryTypes.OPENING]: "Abertura",
  [enHistoryTypes.UPDATE]: "Atualização",
  [enHistoryTypes.CLOSING]: "Fechamento",
  [enHistoryTypes.REOPENING]: "Reabertura",
  [enHistoryTypes.RATED]: "Avaliado pelo cidadão",
  [enHistoryTypes.MESSAGE]: "Adição de menssagem",
  [enHistoryTypes.PUBLIC_STATUS_UPDATE]: "Atualizão pública",
  [enHistoryTypes.PRIVATE_STATUS_UPDATE]: "Atualizão privada",
  [enHistoryTypes.SERVICE_TYPE_UPDATE]: "Modificação do tipo de serviço",
  [enHistoryTypes.SECTOR_TRANSFER_REQUEST]: "Solicitação de transferencia",
  [enHistoryTypes.SECTOR_TRANSFER_DENIED]: "Solicitação de transferencia negada",
  [enHistoryTypes.SECTOR_TRANSFER_ACCEPTED]: "Solicitação de transferencia aprovada",
  [enHistoryTypes.PROVIDER_TRANSFER_REQUEST]: "Solicitação de transferencia de provedor",
  [enHistoryTypes.PROVIDER_TRANSFER_ACCEPTED]: "Solicitação de transferencia de provedor negada",
  [enHistoryTypes.PROVIDER_TRANSFER_DENIED]: "Solicitação de transferencia de provedor aprovada",
};

export const enServiceRequestPublicStatus = {
  ABERTA: "Aberta",
  AVALIADA: "Avaliada",
  FINALIZADA: "Finalizada",
};