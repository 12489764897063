import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: ({ height }) => height,
      margin: "0",
      maxWidth: "100%",
      maxHeight: "none",
      borderRadius: "0",
      bottom: "0",
      position: "absolute",
    },
  },
}));

export default useStyles;
