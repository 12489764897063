import React, { Fragment, useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AppBar from "components/AppBar";
import useStyles from "./styles";
import Card from "@material-ui/core/Card";
import RatingField from "components/Fields/Rating";
import TextField from "components/Fields/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import CardContent from "@material-ui/core/CardContent";
import DissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { Divider } from "@material-ui/core";
import { navigate } from "@reach/router";
import Dialog from "components/Dialog";
import ServiceRequestService from "services/models/serviceRequest";
import { enServiceRequestPublicStatus } from "entities/history";

const detailsInfo = [
  {
    label: "Serviço",
    id: "serviceName",
  },
  {
    label: "Protocolo",
    id: "code",
  },
  {
    label: "Data",
    id: "createdAt",
  },
];

const validationSchema = yup.object({
  stars: yup.number().required(),
});

const ReviewRequest = (props) => {
  const { id } = props;
  const classes = useStyles();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, control } = useForm({ resolver });
  const [store, setStore] = useState();

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await ServiceRequestService.findById(id);
        if ([
          enServiceRequestPublicStatus.FINALIZADA, 
          enServiceRequestPublicStatus.AVALIADA
          ].includes(store?.status?.public)
        ) {
          await navigate(`/s/solicitacao/${id}`);
        }
        setStore({
          ...result,
          serviceName: result.service?.name,
          createdAt:
            result.createdAt &&
            new Date(result.createdAt).toLocaleString("pt-BR"),
        });
      } catch {
        await Dialog.show({
          title: "Atenção",
          message: "Solicitação de serviço não encontrado",
        });
        await navigate(`/s/minhas-solicitacoes`);
      }
    };
    fetch();
  }, [id]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        if(!id) throw new Error('not found id!');
        await ServiceRequestService.review(id, data);
        await Dialog.show({
          title: "Sucesso",
          message: "Avaliação enviada com sucesso!",
        });
        await navigate(`/s/solicitacao/${id}`);
      } catch (err) {
        console.log(err);
      }
    },
    [id]
  );

  const handleBackView = useCallback(async () => {
    await navigate(`/s/solicitacao/${id}`);
  }, [id]);

  return (
    <>
      <AppBar title="Avaliar Solicitação" />
      <Container className={classes.root}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            className={classes.grid}
            justifyContent="space-between"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    align="center"
                    color="secondary"
                  >
                    Avaliar sua solicitação
                  </Typography>
                  <Typography gutterBottom color="primary" align="left">
                    Selecione a quantidade de estrelas referente a sua
                    solicitação abaixo.
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Card>
                    <CardContent>
                      <Grid container justifyContent="space-between">
                        {detailsInfo.map((item) => (
                          <Fragment key={item.label}>
                            <Grid item xs="auto">
                              <Typography color="textPrimary">
                                {item.label}:
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography color="primary" align="right">
                                {store && store[item.id]}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider className={classes.divider} light />
                            </Grid>
                          </Fragment>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs="auto">
                  <RatingField control={control} name="stars" />
                </Grid>
                <Grid item xs={8}>
                  <div className={classes.emojiRating}>
                    <DissatisfiedIcon color="inherit" fontSize="large" />
                    <SatisfiedIcon color="inherit" fontSize="large" />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    control={control}
                    name="comments"
                    label="Observação"
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Avaliar
              </Button>
              <Button
                color="primary"
                variant="text"
                className={classes.backBtn}
                onClick={handleBackView}
                fullWidth
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ReviewRequest;
