import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import useStyles from "./styles";

const defaultProps = {
  variant: "determinate",
  color: "primary",
  size: 80,
  thickness: 4,
};

function ProgressChart(props) {
  const { value, total, label, color, ...others } = props;
  const classes = useStyles(props);
  const progress = Math.round((value * 100) / total, 2);

  return (
    <Card className={classes.root} elevation={6}>
      <Grid container spacing={1} direction="column" alignItems="center">
        <Grid item>
          <div className={classes.progressChart}>
            <div className={classes.value}>
              <Typography variant="h6" component="div" color="primary">
                {value}
              </Typography>
            </div>
            <CircularProgress
              className={classes.bottom}
              {...defaultProps}
              {...others}
              value={100}
            />
            <CircularProgress
              {...defaultProps}
              className={classes.top}
              classes={{
                circle: classes.circle,
              }}
              {...others}
              value={progress}
            />
          </div>
        </Grid>
        <Grid item>
          <Typography className={classes.label} variant="subtitle2" color="primary">
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ProgressChart;
