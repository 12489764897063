import { makeStyles, styled } from "@material-ui/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr auto'
  },
  closeButton: {
    padding: `0 ${theme.spacing(2)}px`,
    color: theme.palette.grey[500],
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: "center",
  },
  filename: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: 40
  },
}));

export const ButtonRemove = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.dark,
  "&:hover": {
    backgroundColor: theme.palette.error.light,
  },
}));

export default useStyles;
