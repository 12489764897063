import { EventEmitter } from "services/emitter";
import { AuthContext } from "components/AuthContext";
import { useContext } from "react";

const LOCALSTORAGE_KEY = "auth_token";

export const getTokenSync = () => localStorage.getItem(LOCALSTORAGE_KEY);
export const emmitLogout = () => EventEmitter.emit("logout");

const useAuthenticate = () => useContext(AuthContext);
export default useAuthenticate;
