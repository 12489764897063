import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { 
    background: "#EEEEEE",
    height: "100%",
    marginRight: 10,
    marginLeft: 10
  }
}));

export default useStyles;