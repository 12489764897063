import AbstractApiService from '../abstractApiService';
import queryString from 'query-string';

export default class NeighborhoodService extends AbstractApiService {
  static basepath = '/neighborhood';

  static async list({ orderBy }) {
    const query = queryString.stringify({ orderBy });
    const response = await this.fetch(`${this.basepath}/?${query}`);
    return response?.result;
  }

}
