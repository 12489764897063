import AbstractApiService from '../abstractApiService';
import FormData from 'form-data';
import queryString from 'query-string';

const enDataPropNames = {
  SERVICE: "service",
  ATTACHMENTS: "attachments",
  DESCRIPTION: "description",
  LOCATION: "location",
};

export default class ServiceRequestService extends AbstractApiService {
  static basepath = '/service-request';

  static async create({ service, description, location, images }) {
    const data = new FormData();
    data.append(enDataPropNames.SERVICE, service._id);
    data.append(enDataPropNames.DESCRIPTION, description);
    data.append(enDataPropNames.LOCATION, JSON.stringify({
      ...location,
      lat: location.latitude,
      lng: location.longitude,
      address: location.display_name
    }));

    images.forEach(({ file }) => data.append(enDataPropNames.ATTACHMENTS, file));

    const response = await this.fetch(`${this.basepath}`, {
      method: 'POST',
      body: data,
    }, true);

    return response.result;
  }

  static async list({ filter='', page = 0, countPerPage = 10, orderBy = 'createdAt', orderDirection = 'desc' }, signal) {
    const _filter = filter?.status ? { ...filter, status: undefined ,"status.public": filter.status } : filter;
    const query = queryString.stringify({ filter: JSON.stringify(_filter), page, countPerPage, orderBy, orderDirection });
    const response = await this.fetch(`${this.basepath}?${query}`,{ signal });
    return response.result;
  }

  static async findById(id) {
    const response = await this.fetch(`${this.basepath}/${id}`);
    return response.result;
  }

  static async review(id, data) {
    const response = await this.fetch(`${this.basepath}/requester-review/${id}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response.result;
  }

}
