import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '88vh',
    width: '100%',
    margin: 0,
    gap: theme.spacing(1)
  },
  serviceTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  backBtn: {
    marginTop: theme.spacing(2)
  },
}));

export default useStyles;