import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { forwardRef, memo, useCallback } from 'react';

import { showGlobalDialog } from './global';

const useStyle = makeStyles({
  root: {
    zIndex: 1600
  },
  content: {
    minWidth: '250px'
  }
});

const Dialog = memo((props) => {
  const classes = useStyle(props);
  const {
    okButtonProps,
    okText,
    confirmation,
    message,
    title,
    onClose
  } = props;

  const handleOk = useCallback(() => onClose(true), [onClose]);
  const handleCancel = useCallback(() => onClose(false), [onClose]);

  return (
    <MuiDialog
      open={props.opened}
      keepMounted
      TransitionComponent={Transition}
      onClose={handleCancel}
      className={classes.root}
    >
      <DialogTitle>{title || (confirmation ? 'Confirmação' : 'Atenção')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.content}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {confirmation && (
          <Button onClick={handleCancel} autoFocus>
            Cancelar
          </Button>
        )}
        <Button {...okButtonProps} autoFocus={!confirmation} onClick={handleOk} color='primary'>
          {okText || 'OK'}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
});

Dialog.show = (params) => {
  const paramsData = typeof params === 'string' ? { message: params } : params;
  return showGlobalDialog(paramsData);
};

Dialog.confirm = (params) => {
  const paramsData = typeof params === 'string' ? { message: params } : params;
  return showGlobalDialog({ ...paramsData, confirmation: true });
};

const Transition = memo(
  forwardRef((props, ref) => {
    return <Slide direction='up' {...props} ref={ref} />;
  })
);

export default Dialog;