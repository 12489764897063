import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "components/Fields/TextField";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { navigate } from "@reach/router";
import DateField from "components/Fields/DateField";
import { signupViews } from "pages/Signup";
import NeighborhoodService from 'services/models/neighborhood';
import { cpf } from 'cpf-cnpj-validator';


const validationSchema = yup.object({
  document: yup.string().required().length(11).test('is-cpf', 'CPF inválido', async (value, testContext) => {
    return cpf.isValid(value.replace(/\D/gi, ''))
  }),
  name: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  phone: yup.string().required().length(11),
  birthday: yup.date().typeError('Campo inválido.').required(),
});

function Signup({ handleChangeView, dispatch, document }) {
  const classes = useStyles();
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [neighborhood, setNeighborhood] = useState();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, control, setValue } = useForm({ resolver });

  useEffect(() => {
    if(document) setValue("document", document);
    setValue("address.city", 'Viana');
  }, [document]);

  const onSubmit = async ({ name,  lastName,...data}) => {

    dispatch({
      ...data,
      name: `${name} ${lastName}`,
      address: {
        ...data['address'],
        neighborhood: neighborhood
      }
    });
    handleChangeView(signupViews.SET_PASSWORD);
  };

  useEffect(() => {
    async function fetch() {
      const { list } = await NeighborhoodService.list({orderBy: null})
      setNeighborhoods(list.sort((a, b) => a.name.localeCompare(b.name)))
    }
    fetch();
  }, []);

  const changeNeighborhood = useCallback((evt) => {
    setNeighborhood(evt.target.value)
  }, []);

  const handleBack = () => {
    navigate("/login");
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <TextField
            control={control}
            name="document"
            label="CPF"
            mask="cpf"
            type="tel"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="name"
            label="Nome"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="lastName"
            label="Sobrenome"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="email"
            label="E-mail"
            type="email"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="phone"
            label="Telefone"
            mask="phone"
            type="tel"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <DateField
            control={control}
            name="birthday"
            label="Data de nascimento"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="address.city"
            label="Cidade"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{width: '100%', marginTop: 10}}variant="outlined">
            <InputLabel>Bairro</InputLabel>
            <Select
              label="Bairro"
              name="address.neighborhood"
              fullWidth
              value={neighborhood}
              onChange={changeNeighborhood}
            >
              { neighborhoods.map(a => (
                <MenuItem key={a._id} value={a.name}>{a.name}</MenuItem>
              )) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={7} className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            endIcon={<ArrowForwardIcon />}
            fullWidth
          >
            Continuar
          </Button>
          <Button
            variant="text"
            color="primary"
            disableTouchRipple
            className={classes.backBtn}
            size="large"
            onClick={handleBack}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Signup;
