import React, { useCallback } from "react";
import DrawerBottom from "components/DrawerBottom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { enServiceRequestStatus } from "entities/serviceRequest";


function Filter(props) {
  const { onToggleFilter, open, onChangeFilter, filter } = props;
  const classes = useStyles();

  const handleBackView = useCallback(() => {
    onToggleFilter(false);
  }, [onToggleFilter]);

  const handleFilter = useCallback(() => {
    onToggleFilter(false);
  }, [onToggleFilter]);

  return (
    <DrawerBottom open={open} onToggleDrawer={onToggleFilter}>
      <Container>
        <CardContent className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                FILTRAR
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Status:
              </Typography>
            </Grid>
            {Object.keys(enServiceRequestStatus).map((i) => (
              <Grid item xs={4} key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={i}
                      checked={filter?.status?.includes(enServiceRequestStatus[i])}
                      onChange={onChangeFilter(enServiceRequestStatus[i])}
                    />
                  }
                  label={enServiceRequestStatus[i]}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleFilter}
            fullWidth
          >
            Confirmar
          </Button>
          <Button
            color="primary"
            variant="text"
            fullWidth
            onClick={handleBackView}
          >
            Voltar
          </Button>
        </CardActions>
      </Container>
    </DrawerBottom>
  );
}

export default Filter;
