import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import ProgressChart from "components/ProgressChart";
import {
  enServiceRequestColor,
  enServiceRequestStatus,
} from "entities/serviceRequest";
import { CardActionArea } from "@material-ui/core";
import { navigate } from "@reach/router";

const Charts = ({ data }) => {
  const classes = useStyles();

  const handleRedirect = (status) => () => {
    navigate("/s/minhas-solicitacoes", {
      state: { filter: { status: [enServiceRequestStatus[status]] } },
    })
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.root}
      spacing={2}
    >
      {Object.keys(enServiceRequestStatus).map((i) => (
        <Grid item xs={6} key={i}>
          <CardActionArea onClick={handleRedirect(i)}>
            <ProgressChart
              label={enServiceRequestStatus[i]}
              color={enServiceRequestColor[i]}
              value={data && data[i] ? data[i] : 0}
              loading={!data}
              total={data?.total || 0}
            />
          </CardActionArea>
        </Grid>
      ))}
    </Grid>
  );
};

export default Charts;
