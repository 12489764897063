import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    background: 'transparent',
    border: 0
  },
  icon: {
    fontSize: theme.spacing(4),
    color: theme.palette.primary.main
  }
}));

export default useStyles;