import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "components/Fields/TextField";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { loginViews } from "pages/Login";
import Dialog from "components/Dialog";
import { navigate } from "@reach/router";
import AuthenticateService from "services/authenticate";
import useAuthenticate from "hooks/useAuthenticate";

const validationSchema = yup.object({
  document: yup.string().required().length(11),
});

const validationSchemaLogin = yup.object({
  document: yup.string().required().length(11),
  password: yup.string().required(),
});

const dialogRegister = {
  title: "Criar Usuário",
  confirmation: true,
  message: "CPF não encontraado, clique em registrar para criar o usuário",
  okText: "Registrar",
  okButtonProps: {},
};

function CheckDocument({ handleChangeView, locationState }) {
  const classes = useStyles();
  const [hasAccount, setHasAccount] = useState(false);
  const resolver = useYupValidationResolver(
    hasAccount ? validationSchemaLogin : validationSchema
  );
  const { handleSubmit, control, reset, setError, setFocus } = useForm({ resolver });
  const { isLogged, login } = useAuthenticate();
  const nextRoute = locationState?.nextRoute;

  useEffect(() => {
    if (isLogged) {
      navigate(nextRoute || "/s/inicio");
    }
  }, [nextRoute, isLogged]);

  const checkIfDocumentExist = useCallback(async (data) => {
    try {
      await AuthenticateService.getEmail(data.document);
      setHasAccount(true);
      setFocus('password');
    } catch (error) {
      if (error.message === "API Erro!\nModel not found!\n") {
        const result = await Dialog.show(dialogRegister);
        if (result) navigate("/signup", { state: { document: data.document } });
      }
    }
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      if (hasAccount) {
        try {
          const response = await AuthenticateService.login(data);
          login(response.token);
        } catch (error) {
          setError("password", { type: "manual", message: "Senha inválida" });
        }
      } else {
        checkIfDocumentExist(data);
      }
    },
    [hasAccount, login, setError, checkIfDocumentExist]
  );

  const resetForm = useCallback(() => {
    reset();
    setHasAccount(false);
  }, [reset]);

  const handleBack = useCallback(() => {
    hasAccount ? resetForm() : navigate("/intro");
  }, [hasAccount, resetForm]);

  const handleForgetPassword = () => {
    handleChangeView(loginViews.FORGET_PASSWORD);
    resetForm();
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Grid container alignItems="stretch">
            <Grid item xs={12}>
              <TextField
                control={control}
                name="document"
                label="CPF"
                mask="cpf"
                required
                disabled={hasAccount}
                fullWidth
              />
            </Grid>
            <Collapse component={Grid} item xs={12} in={hasAccount}>
              <TextField
                control={control}
                name="password"
                type="password"
                label="Senha"
                required
                fullWidth
              />
              <Typography
                color="textSecondary"
                className={classes.forgetPassword}
              >
                Esqueceu sua senha?{" "}
                <Link underline="always" onClick={handleForgetPassword}>
                  Clique aqui
                </Link>
              </Typography>
            </Collapse>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            type="submit"
            endIcon={<ArrowForwardIcon color="inherit" />}
            fullWidth
          >
            continuar
          </Button>
          <Button
            variant="text"
            color="primary"
            disableTouchRipple
            className={classes.backBtn}
            onClick={handleBack}
            size="large"
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default CheckDocument;
