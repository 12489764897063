import AbstractApiService from './abstractApiService';

export default class DashboardService extends AbstractApiService {
  static basepath = '/dashboard';

  static async find() {
    const response = await this.fetch(`${this.basepath}/public-user`);
    return response.result;
  }
}
