import React, { memo, useCallback, useEffect, useState } from 'react';

import Dialog from '.';

let lastPromise = Promise.resolve(false);
let globalDialog;

const DialogGlobalProvider = memo(() => {
  const [opened, setOpened] = useState(false);
  const [params, setParams] = useState();
  const [promiseResolve, setPromiseResolve] = useState();

  const handleShow = useCallback((params) => {
    const result = new Promise(resolve => {
      setPromiseResolve(() => resolve);
      setOpened(true);
      setParams({ confirmation: false, title: null, ...params });
    });

    result.then(() => setOpened(false));
    return result;
  }, []);

  const onClose = useCallback(
    (ok) => {
      promiseResolve && promiseResolve(ok);
    },
    [promiseResolve]
  );

  useEffect(() => {
    if (globalDialog) throw new Error('Only one Dialog.Global can be initialized');
    globalDialog = handleShow;
  }, [handleShow]);

  return <Dialog opened={opened} {...params} onClose={onClose} />;
});

export async function showGlobalDialog(params) {
  if (!globalDialog) throw new Error('Please, initialize an Dialog.Global before');

  //prevent an Dialog to overhide another
  return (lastPromise = lastPromise.then(async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 300));
    return globalDialog(params);
  }));
}

export default DialogGlobalProvider;