import React, { useEffect, useState } from "react";
import { EventEmitter } from "../services/emitter";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import { emmitLogout } from "hooks/useAuthenticate";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ChatIcon from '@material-ui/icons/Chat';
import useAuthenticate from "hooks/useAuthenticate";
import Brasao from 'assets/images/logo/brasaopref.png';
import Viana156 from 'assets/images/logo/viana156.jpeg';

const MENUS = [
  {
    icon: <DashboardIcon />,
    label: "Início",
    action: () => navigate("/s/inicio"),
    loggedRequired: true
  },
  {
    label: "Termos de privacidade",
    icon: <MenuBookIcon />,
    action: () => navigate("/s/termos-de-privacidade"),
  },
  {
    label: "Contato",
    icon: <ChatIcon />,
    action: () => navigate("/s/contato"),
  },
  {
    label: "Logout",
    icon: <ExitToAppIcon/>,
    action: emmitLogout,
    loggedRequired: true
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  liIcon: {
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  images: {
    display: "flex",
    justifyContent: 'space-between',
    alignContent: 'space-between',
    padding: theme.spacing(8),
    "& > *": {
      width: "50%"
    }
  }
}));

const Menu = () => {
  const classes = useStyles();
  const { isLogged } = useAuthenticate();
  const [open, setOpen] = useState(false);

  const onToggle = () => setOpen((o) => !o);
  const onClose = () => setOpen(false);


  useEffect(() => {
    EventEmitter.on("menu-toggle", onToggle);
    return () => EventEmitter.off("menu-toggle", onToggle);
  }, []);

  const handleClick = (action) => () => {
    action && action();
    onClose && onClose();
  }

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className={classes.images}>
        <img src={Brasao} />
        <img src={Viana156} />
      </div>
      <List className={classes.root}>
        {MENUS.filter(i => isLogged || !i.loggedRequired).map((menu) => (
          <ListItem key={`link-${menu.label}`} onClick={handleClick(menu.action)} disableGutters button>
            <ListItemIcon className={classes.liIcon}>{menu.icon}</ListItemIcon>
            <ListItemText primary={menu.label} />
          </ListItem>
        ))}
      </List>

    </Drawer>
  );
};

export default Menu;
