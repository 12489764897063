export const fields = [
  {
    name: "document",
    label: "CPF",
    mask: "cpf",
    disabled: true
  },
  {
    name: "name",
    label: "Nome",
  },
  {
    name: "lastName",
    label: "Sobrenome",
  },
  {
    name: "email",
    label: "E-mail",
    type: "email"
  },
  {
    name: "phone",
    label: "Telefone",
    mask: "phone",
  },
  {
    name: "birthday",
    nameText: "birthdayText",
    label: "Data de nascimento",
    fieldType: 'date'
  },
  {
    name: "address.city",
    label: "Cidade",
    disabled: true
  },
  {
    name: "address.neighborhood",
    label: "Bairro",
    disabled: true
  }
];
