import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import AppBar from "components/AppBar";
import TutorialSlide from "components/TutorialSlide";
import useStyles from "./styles";
import { navigate } from "@reach/router";
import useAuthenticate from "hooks/useAuthenticate";

const IntroPage = () => {
  const classes = useStyles();
  const { isLogged } = useAuthenticate();

  const handleNavigate = async () => {
    await navigate(`/s/solicitar-um-servico`)
  }

  useEffect(() => {
    setTimeout(async () => {
      if(isLogged){
        await navigate(`/s/inicio`)
      }
    }, 300)
  }, [isLogged])

  return (
    <>
      <AppBar title="início" />
      <div className={classes.root}>
        <Container>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography align="center" variant="h6" color="primary">
                Como funciona?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TutorialSlide />
            </Grid>
            <Grid item xs={8} sm={6} md={4}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                fullWidth
                onClick={handleNavigate}
              >
                Nova Demanda
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default IntroPage;
