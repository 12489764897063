import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AppBar from "components/AppBar";
import Carousel from "react-swipeable-views";

import useStyles from "./styles";
import Signup from "components/SignupViews/Signup";
import SetPassword from "components/SignupViews/SetPassword";

export const signupViews = {
  SIGNUP: 0,
  SET_PASSWORD: 1
};

const SignupPage = ({ location }) => {
  const classes = useStyles();
  const [currentView, seCurrentView] = useState(signupViews.SIGNUP);
  const { document } = location.state;
  const [store, setStore] = useState({});
  
  const handleChangeView = (view) => {
    seCurrentView(view);
  };

  const dispatch = (data) => {
    setStore((store) => ({ ...store, ...data }));
  };

  const handleBackView = () => {
    if (currentView > 0) seCurrentView(currentView - 1);
  };

  return (
    <>
      <AppBar title="Solicitar um serviço" />
      <div className={classes.root}>
        <Container>
          <Grid 
            container
            justifyContent="space-between"
            align="center"
            direction="column"
            spacing={2}
          >
            <Grid item xs="auto">
              <Typography gutterBottom variant="h6" align="center" color="secondary">
                Realizar login
              </Typography>
              <Typography gutterBottom color="primary" align="left">
                Para solicitar um novo serviço é necessário que 
                você preencha os campos abaixo.
              </Typography>
            </Grid>
            <Grid item xs>
              <Carousel index={currentView} disabled>
                <Signup handleChangeView={handleChangeView} dispatch={dispatch} document={document} />
                <SetPassword handleBackView={handleBackView} store={store} />
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default SignupPage;
