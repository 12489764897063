import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    height: '40vh'
  },
  title: {
    textTransform: 'uppercase'
  }
}));

export default useStyles;