import React, { useEffect, useState, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapService from "services/map";
import { useDebounce } from "use-debounce";

function SearchInput(props) {
  const { onSelect, inputValue, setInputValue } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(null);
  const [options, setOptions] = useState([]);
  const [valueDebounce] = useDebounce(inputValue, 2000);

  useEffect(() => {
    let controller = new AbortController();
    let { signal } = controller;

    const handleSearch = async () => {
      controller.abort();
      controller = new AbortController();
      signal = controller.signal;
      setLoading(true);
      if(valueDebounce){
        const finded = await MapService.searchPlace(valueDebounce, signal);
        setOptions(finded || []);
      } else {
        setOptions([]);
      }
      setLoading(false);
    };
    handleSearch();

    return () => {
      controller.abort();
    };
  }, [valueDebounce]);

  const renderOptions = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={classes.icon} />
        </Grid>
        <Grid item xs>
          <Typography variant="body2" color="textSecondary">
            {option?.display_name || ""}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const handleChange = (event, newValue) => {
    setInputValue(newValue?.display_name || "");
    if(newValue){
      onSelect(newValue || undefined);
    }
  };

  const handleInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  const renderInput = (params) => (
    <TextField
      {...params}
      label="Inserir localização"
      variant="outlined"
      fullWidth
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      fullWidth
      autoComplete
      getOptionLabel={(option) => option}
      getOptionSelected={(o, v) =>  o === v}
      filterOptions={(x) => x}
      options={options}
      loading={loading}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={inputValue}
      inputValue={inputValue}
      noOptionsText="Nenhuma opção encontrada"
      renderInput={renderInput}
      renderOption={renderOptions}
    />
  );
}

export default SearchInput;
