import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ArrowRight from "@material-ui/icons/ArrowRight";
import React from "react";
import renderHTML from "react-render-html";
import useStyles from "./styles";
import { enHistoryTypeLabel } from "entities/history";
import useAuthenticate from "hooks/useAuthenticate";

function ResumedItemDescription(props) {
  const {
    message,
    onClickInfo,
    type,
    createdAt,
    requester,
    footerInfo
  } = props;
  const classes = useStyles();
  const { userLogged } = useAuthenticate();

  return (
    <Box className={classes.root} paddingTop="8px">
      <ArrowRight className={classes.arrow} />
      <Box display="flex" justifyContent="space-between" paddingBottom="8px">
        <Typography>{new Date(createdAt).toLocaleDateString('pt-BR')}</Typography>
        <Typography variant="caption" color="textSecondary">
          {new Date(createdAt).toLocaleTimeString('pt-BR')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" paddingLeft=".5rem">
        <Box>
          <Typography className={classes.textDescription}>
            <b>{enHistoryTypeLabel[type]}: </b> {renderHTML(message)}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          py="4px"
          alignItems="center"
        >
          {requester !== userLogged.name && (
            <Typography className={classes.authorText}>
              <b>{requester}: </b> {footerInfo}
            </Typography>
          )}
          <Typography onClick={onClickInfo} className={classes.link}>
            Ver mais
          </Typography>
        </Box>
      </Box>
      <Divider flexItem />
    </Box>
  );
}

export default ResumedItemDescription;