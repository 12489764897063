export const enServiceRequestStatus = {
  ABERTA: "Aberta",
  AVALIADA: "Avaliada",
  FINALIZADA: "Finalizada",
};

export const enServiceRequestColor = {
  ABERTA: "primary",
  AVALIADA: "#E1C712",
  FINALIZADA: "#13AC8D",
};