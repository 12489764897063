import AbstractApiService from './abstractApiService';
import queryString from 'query-string';

export default class AuthenticateService extends AbstractApiService {
  static basepath = '/auth';

  static async login(data) {
    const response = await this.fetch(`${this.basepath}/login`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response;
  }
  
  static async register(data) {
    const response = await this.fetch(`${this.basepath}/register`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response;
  }
  
  static async getEmail(document) {
    const query = queryString.stringify({ 
      document
    });
    const response = await this.fetch(`${this.basepath}/email?${query}`, {
      method: 'GET',
    });
    return response;
  }

  static async sendRecoveryPassword(document) {
    const response = await this.fetch(`${this.basepath}/recovery-password`, {
      method: 'POST',
      body: JSON.stringify({ document })
    });
    return response;
  }
}
