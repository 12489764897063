import AbstractApiService from '../abstractApiService';
import queryString from 'query-string';

export default class ServiceService extends AbstractApiService {
  static basepath = '/service';

  static async list({ filter='', page = 0, countPerPage = 10, orderBy = 'name', orderDirection = 'desc' }, signal) {
    const query = queryString.stringify({ filter, page, countPerPage, orderBy, orderDirection });
    const response = await this.fetch(`${this.basepath}?${query}`,{ signal }); 
    return response;
  }
  
}
