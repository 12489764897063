import IconButton from "@material-ui/core/IconButton";
import React from "react";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from "@material-ui/core/Typography";
import { emmitLogout } from "hooks/useAuthenticate";

function ButtonLogout() {
  const handlLogout = () => {
    emmitLogout()
  };

  return (
    <Typography color='error'>
      <IconButton
        onClick={handlLogout}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <ExitToAppIcon fontSize='large' />
      </IconButton>
    </Typography>
  );
}

export default ButtonLogout;
