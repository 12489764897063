import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.common.white
  },
  icon: {
    padding: 0
  }
}));

export default useStyles;