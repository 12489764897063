import Utils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import React, { memo, useCallback } from "react";
import ptBR from "date-fns/locale/pt-BR";
import { useController } from 'react-hook-form';

const DateField = memo((props) => {
  const { control, defaultValue, name, error, helperText, ...others } = props;
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState
  } = useController({
    name,
    control,
    defaultValue: defaultValue || null
  });

  const handleChange = useCallback((date) => {
    onChange({ target: { value: date } });
  }, [onChange]);

  return (
    <MuiPickersUtilsProvider utils={Utils} locale={ptBR}>
      <KeyboardDatePicker
        {...inputProps}
        openTo="year"
        format="dd/MM/yyyy"
        views={["year", "month", "date"]}
        disableFuture
        clearable
        clearLabel="limpar"
        {...others}
        value={value}
        onChange={handleChange}
        error={fieldState.invalid || error}
        helperText={fieldState?.error?.message || helperText}
      />
    </MuiPickersUtilsProvider>
  );
})

export default DateField;