import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  li: {
    padding: 0,
    color: theme.palette.common.white
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(2)
  }
}));

export default useStyles;
