import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  line: {
    background: ({ color }) => color,
    marginLeft: "25px",
    width: "25px",
  },
  header: {
    position: "relative",
    fontSize: "1.5rem",
    textTransform: "uppercase",
  },
}));

export default useStyles;
