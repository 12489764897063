import React from "react";
import { Redirect } from "@reach/router";
import useAuthenticate from "hooks/useAuthenticate";

export default ({ children }) => {
  const { pathname } = children?.props?.location;
  const { isLogged } = useAuthenticate();

  return (
    <>
      {isLogged ? children : <Redirect to="/login" state={{ nextRoute: pathname || "/s/inicio" }} noThrow/>}
    </>
  );
};
