import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AppBar from "components/AppBar";
import useStyles from "./styles";
import { navigate } from "@reach/router";
import TextField from "@material-ui/core/TextField";
import ImageField from "components/Fields/ImageField";
import { useState } from "react";
import { useEffect } from "react";
import ServiceRequestService from "services/models/serviceRequest";
import Dialog from "components/Dialog";
import History from "components/History";
import { enServiceRequestPublicStatus } from "entities/history";
import { formatLocationLabel } from 'utils/formatLocationLabel';


const stripHTML = (value) => {
  const div = document.createElement("div");
  div.innerHTML = value;
  return div.textContent || div.innerText || "";
}

const DetailsService = (props) => {
  const { id } = props;
  const [store, setStore] = useState();
  const classes = useStyles();

  useEffect(() => {
    const fetch = async () => {
      try {
        const images = [];
        const result = await ServiceRequestService.findById(id);
        result.history.forEach(i => {
          i.attachments.forEach(element => {
            images.push(element);
          });
        });
        setStore({ ...result, images });
      } catch {
        await Dialog.show({
          title: "Atenção",
          message: "Solicitação de serviço não encontrado",
        });
        await navigate(`/s/minhas-solicitacoes`);
      }
    };
    fetch();
  }, [id]);

  const handleNavigate = (path) => async () => {
    await navigate(path);
  };

  return (
    <>
      <AppBar title="Minhas Solicitações" />
      <div className={classes.root}>
        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            className={classes.root}
          >
            <Grid item>
              <Typography color="primary" variant="h6">
                Detalhes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                readOnly
                disabled
                InputLabelProps={{ shrink: true }}
                label="Número do protocolo"
                value={store?.code}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                readOnly
                disabled
                InputLabelProps={{ shrink: true }}
                label="Horário da solicitação"
                value={
                  store?.createdAt &&
                  new Date(store.createdAt).toLocaleString("pt-BR")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                readOnly
                disabled
                InputLabelProps={{ shrink: true }}
                label="Localização *"
                value={formatLocationLabel(store?.location?.address)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                readOnly
                disabled
                InputLabelProps={{ shrink: true }}
                label="Tipo de serviço *"
                value={store?.service?.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição"
                required
                multiline
                disabled
                InputLabelProps={{ shrink: true }}
                value={stripHTML(store?.description)}
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageField
                label="Fotos"
                name="images"
                maxLength={store?.images?.length}
                value={store?.images}
                disabled
                accept="image/*"
              />
            </Grid>
            <Grid item xs={12}>
              {store && <History demand={store} />}
            </Grid>
            {[enServiceRequestPublicStatus.FINALIZADA].includes(store?.status?.public) && (
              <Grid item xs={8}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={handleNavigate(`/s/avaliar-solicitacao/${id}`)}
                >
                  Fazer Avaliação
                </Button>
              </Grid>
            )}
            <Grid item xs={8}>
              <Button
                color="primary"
                variant="text"
                fullWidth
                onClick={handleNavigate("/s/minhas-solicitacoes")}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default DetailsService;
