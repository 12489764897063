import {
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React, { useMemo } from "react";
import useStyles from "./styles";
import { enHistoryTypeLabel } from "entities/history";
import renderHTML from "react-render-html";

const getLabelType = (type) => {
  const val = enHistoryTypeLabel[type];
  return val;
};

function ItemDescription({
  type,
  message,
  onClickInfo,
  showInfoBtn,
}) {
  const classes = useStyles();
  const typeLabel = useMemo(() => getLabelType(type), [type]);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" component="h6">
          {typeLabel}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">{renderHTML(message)}</Typography>
      </Box>
      {showInfoBtn && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={onClickInfo}
            color="primary"
            className={classes.iconButton}
          >
            <InfoOutlined />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default ItemDescription;
