class Enviroment {
  static get() {
    const HOST = window.location.hostname;

    if (HOST.includes('localhost') || process.env.NODE_ENV === 'development') {
      return 'http://localhost:3100';
    }

    if(HOST.includes('demanda-prefeitura-cidadao-web-homolog.s3')){
      return 'https://prefeitura-demanda-api.cerconsultoria.com';
    }

    if(HOST.includes('prefeitura-demanda-cidadao.cerconsultoria')){
      return 'https://prefeitura-demanda-api.cerconsultoria.com';
    }

    throw new Error("HOST inválido!");
  }
}

export default Enviroment;
