import React, { memo, useRef, useState, useCallback, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";
import PreviewPhoto from "./PreviewPhoto";
import { formatToBase64 } from "utils/formatToBase64";
import { useController } from "react-hook-form";
import { Typography } from "@material-ui/core";

const DocumentFileReader = memo((props) => {
  const { label, maxLength = 0, control, name, defaultValue, accept, disabled } = props;
  let value= props.value || [];
  let onChange= props.onChage || function(){};
  const classes = useStyles();
  const [openPreview, setOpenPreview] = useState(false);
  const [current, setCurrent] = useState();
  const ref = useRef(null);

  if(control){
    const {
      field
    } = useController({
      name,
      control,
      defaultValue: defaultValue || []
    });
    value = field.value;
    onChange = field.onChange;
  }

  const onExited = useEffect(()=> {
    setCurrent(undefined);
  }, [])

  const handleSelectImage = () => {
    ref.current.click();
  };

  const onFileSelected = useCallback(async () => {
    const { length } = ref?.current?.files;
    if (!length) return;

    if (maxLength && length + value.length > maxLength) {
      return alert(`Só pode ser selecionado ${maxLength} fotos`);
    }

    const files = await Promise.all(
      Array.from(ref.current.files).map(async (i) => ({
        file: i,
        base64: await formatToBase64(i),
      }))
    );

    onChange([...value, ...files].map((i, idx) => ({ ...i, idx})));
  }, [value, maxLength]);

  const handlePreviewPhoto = useCallback(
    (idx) => () => {
      setCurrent(value[idx]);
      setOpenPreview(true);
    },
    [value]
  );

  const handleClosePreview = () => {
    setOpenPreview(false);
    
  };

  const handleRemovePhoto = useCallback(() => {
    onChange(value.filter((f) => f.idx !== current.idx));
    handleClosePreview();
  }, [current,value]);

  return (
    <>
      <fieldset className={classes.fieldset}>
        <Typography component='legend' variant='caption' className={classes.legend}>{label}</Typography>
        <input
          type="file"
          ref={ref}
          style={{ display: "none" }}
          onChange={onFileSelected}
          accept={accept}
          disabled={disabled}
          multiple
          capture
        />
        <Grid container>
          {value?.map((i, idx) => (
            <Grid item key={typeof i ==="string" ? i :i.base64} xs={4} sm="auto">
              <IconButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={handlePreviewPhoto(idx)}
              >
                <Avatar
                  variant="square"
                  className={classes.avatar}
                  src={typeof i ==="string" ? i :i.base64}
                />
              </IconButton>
            </Grid>
          ))}
          {Boolean(maxLength - value.length) && (
            <Grid item xs={4} sm="auto">
              <IconButton onClick={handleSelectImage}>
                <Avatar variant="square" className={classes.avatar}>
                  +{maxLength - value.length}
                </Avatar>
              </IconButton>
            </Grid>
          )}
        </Grid>
      </fieldset>
      <PreviewPhoto
        open={openPreview}
        onClose={handleClosePreview}
        onRemovePhoto={handleRemovePhoto}
        onExited={onExited}
        disabled={disabled}
        current={current}
      />
    </>
  );
});

export default DocumentFileReader;
