import createTheme from '@material-ui/core/styles/createTheme';

import overrides from './overrides';
import props from './props';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  overrides,
  props,
  typography
})

export default theme;
