import "utils/yup";
import React from "react";
import "./assets/App.css";
import Router from "./routes";
import Menu from "./components/menu";
import { ThemeProvider } from "@material-ui/core/styles/";
import theme from "./assets/theme";
import DialogGlobalProvider from "components/Dialog/global";
import AuthContextProvider from "components/AuthContext";

function App() {
  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <Router />
        <Menu />
        <DialogGlobalProvider />
      </ThemeProvider>
    </AuthContextProvider>
  );
}

export default App;
