import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import useStyles, { Button, BgColor, MenuBgColor } from "./styles";
import { navigate } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";

import MenuIcon from "@material-ui/icons/MenuRounded";
import ListIcon from "@material-ui/icons/FormatListBulleted";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import Profile from "components/Home/Profile";
import Charts from "components/Home/Charts";
import { EventEmitter } from "services/emitter";
import DashboardService from "services/dashboard";

const HomePage = () => {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await DashboardService.find();
      setDashboardData(data);
    };
    fetchData();
  }, []);

  const redirect = (path) => async () => {
    await navigate(path);
  };

  return (
    <div className={classes.root}>
      <BgColor />
      <MenuBgColor />
      <Container>
        <Grid container justifyContent="center" spacing={0}>
          <Grid item xs>
            <Profile onClick={redirect('/s/perfil')} lastServiceRequest={dashboardData?.lastServiceRequest}/>
          </Grid>
          <Grid item xs="auto">
            <IconButton size="medium" onClick={() => EventEmitter.emit("menu-toggle")}>
              <MenuIcon fontSize="large" className={classes.menu} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold} align="center" variant="subtitle1" color="primary">
              MINHAS SOLICITAÇÕES
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Charts data={dashboardData?.status}  />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold} align="center" variant="h5" color="secondary">
              Escolha uma ação abaixo
            </Typography>
            <Typography align="center" variant="h5" color="secondary">
              <ArrowDownwardIcon />
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Button
              startIcon={<ListIcon />}
              variant="outlined"
              color="secondary"
              size="large"
              onClick={redirect("/s/minhas-solicitacoes")}
            >
              Minhas Solicitações
            </Button>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="secondary"
              size="large"
              onClick={redirect("/s/solicitar-um-servico")}
            >
              Nova Solicitação
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HomePage;
