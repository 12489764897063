import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 80,
    width: 80
  },
  fieldset: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4
  },
  legend: {
    paddingLeft: 5,
    paddingRight: 5
  }
}));

export default useStyles;