import AbstractApiService from '../abstractApiService';
import queryString from 'query-string';

export default class UserService extends AbstractApiService {
  static basepath = '/user';

  static async list(filter='', page = 0, countPerPage = 10, orderBy = {}) {
    const query = queryString.stringify({ 
      filter, 
      page, 
      countPerPage, 
      orderBy: JSON.stringify(orderBy) 
    });
    const response = await this.fetch(`${this.basepath}/list?${query}`);
    return response;
  }

  static async findById(id) {
    const response = await this.fetch(`${this.basepath}/${id}`);
    return response.result;
  }

  static async save(data) {
    const response = await this.fetch(`${this.basepath}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response;
  }

  static async updateAvatar(file) {
    const data = new FormData();
    data.append('avatar', file);

    const response = await this.fetch(`${this.basepath}/avatar`, {
      method: 'POST',
      body: data,
    }, true);

    return response.result;
  }

  // static async remove(id) {
  //   const response = await this.fetch(`${this.basepath}/${id}`, {
  //     method: 'DELETE',
  //   });
  //   return response;
  // }
}
