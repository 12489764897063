import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  historyPreviewContainer: {
    border: `.85px solid ${theme.palette.text.secondary}`,
    height: "200px",
    overflow: "scroll",
    margin: theme.spacing(2, 0),
  },
  headerModal: {
    color: theme.palette.primary.main,
  },
  modalBody: {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
  },

  timeline: {
    paddingBottom: "40px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  btn: {},
  collapse: {
    position: "relative",
  },
}));

export default useStyles;
