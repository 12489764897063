import React from 'react';
import MuiCard from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './styles';
import Box from '@material-ui/core/Box';

function Card({ data }) {
  const classes = useStyles();

  return (
    <MuiCard elevation={0} className={classes.root}>
      <CardContent>
        <Grid container direction="column" justifyContent="center" spacing={1}>
          <Grid item>
            <Box align="center" fontWeight="fontWeightMedium" m={1} color='primary'>
              {data.title}
            </Box>
          </Grid>
          <Grid item>
            <Box align="center">
              {data.image}
            </Box>
          </Grid>
          <Grid item>
            <Box align="center">
              {data.description}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </MuiCard>
  );
}

export default Card;