import React, { useEffect, useState } from "react";
import SearchInput from "./SearchInput";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { serviceViews } from "pages/RequestService/New";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import useStyles from "./styles";
import Map from "components/Map";
import MapService from "services/map";
import LocaleService from "services/models/locale";
import Dialog from "components/Dialog";
import { useCallback } from "react";

const LocationService = (props) => {
  const { dispatch, handleChangeView, store } = props;
  const classes = useStyles();
  const [mapPosition, setMapPosition] = useState();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    dispatch({ location: {...store.location, address: inputValue }});
  }, [inputValue, dispatch]);

  const handleNextPage = useCallback(async () => {
    const { latitude: lat, longitude: lng } = store.location || {};
    const isAvailble = await LocaleService.check({ lat, lng });
    if (!isAvailble?.result) {
      return Dialog.show({
        title: "Atenção",
        message: "Não é possivel solicitar serviço para este endereço",
      });
    }
    handleChangeView(serviceViews.SELECTION);
  }, [store.location, inputValue, dispatch, handleChangeView]);

  const handleBackView = async () => {
    await navigate("/s/inicio");
  };

  const handleSelect = (location) => {
    if (!location?.display_name) return;
    dispatch({ location });
    const { lat, lon } = location;
    setMapPosition([lat, lon]);
  };

  const handleChangeMap = async (position) => {
    setMapPosition(position);
    const [latitude, longitude] = position;
    const finded = await MapService.searchPlaceByLatLng(latitude, longitude);
    const firstElement = finded;
    if (firstElement) {
      dispatch({ location: { ...firstElement, latitude, longitude } });
      setInputValue(firstElement.display_name);
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12}>
            <Typography color="primary" variant="body2" gutterBottom>
              Para solicitar um novo serviço é necessário que você insira a
              localização de onde o serviço deveria ocorrer.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SearchInput
              position={mapPosition}
              inputValue={inputValue}
              setInputValue={setInputValue}
              onSelect={handleSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <Map
              position={mapPosition}
              onChangePostion={handleChangeMap}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleNextPage}
          disabled={!store?.location}
          fullWidth
        >
          Confirmar Localização
        </Button>
        <Button
          color="primary"
          variant="text"
          fullWidth
          className={classes.backBtn}
          onClick={handleBackView}
        >
          Cancelar
        </Button>
      </Grid>
    </Grid>
  );
};

export default LocationService;
