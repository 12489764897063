import React, { useEffect, useMemo, useState } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import useStyles from "./styles";
import { useDebounce } from "use-debounce";
import Dialog from "components/Dialog";

function LocationMarker(props) {
  const { onChangePostion, location } = props;
  const classes = useStyles();
  const [marker, setMarker] = useState();
  const map = useMapEvents({
    load() {
      map.locate();
    },
  });
  const [dMarker] = useDebounce(marker, 3000);

  useEffect(() => {
    const { lat = 0, lng = 0 } = dMarker || {};
    onChangePostion([lat, lng]);
  }, [dMarker]);

  useEffect(() => {
    map.setView(location || [-14.235, -51.9253]);
  }, [location]);

  useEffect(() => {
    map.addEventListener("move", async () => {
      setMarker(map.getCenter());
    });

    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      map.setView([latitude, longitude]);
      onChangePostion([latitude, longitude]);
    }, (error) => {
      let errorMsg = "";
      switch(error.code) {
        case error.PERMISSION_DENIED:
          errorMsg = "User denied the request for Geolocation."
          break;
        case error.POSITION_UNAVAILABLE:
          errorMsg = "Location information is unavailable."
          break;
        case error.TIMEOUT:
          errorMsg = "The request to get user location timed out."
          break;
        case error.UNKNOWN_ERROR:
          errorMsg = "An unknown error occurred."
          break;
      }
      console.log(error);
      Dialog.show({
        title: "Atenção",
        message: errorMsg,
      });
    });
  }, []);

  const mapPackageIcon = useMemo(() => {
    return new DivIcon({
      html: `<span class="material-icons ${classes.icon}">place</span>`,
      iconSize: [24, 24],
      className: classes.iconContainer,
    });
  }, [classes.icon, classes.iconContainer]);

  if (!marker) return null;

  return <Marker position={marker} icon={mapPackageIcon} />;
}

const Map = (props) => {
  const { position, onChangePostion, polygons=[] } = props;

  return (
    <MapContainer
      center={[-14.235, -51.9253]}
      zoom={18}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "50vh" }}
    >
      <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LocationMarker location={position} onChangePostion={onChangePostion} />
      {polygons?.map((polygon) => (
        <Polygon pathOptions={{ color: "lightgreen" }} positions={polygon} />
      ))}
    </MapContainer>
  );
};

export default Map;
