import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import useStyles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function HalfwayDialog({
  open,
  handleClose,
  children,
  height = "70%",
  ...others
}) {
  const classes = useStyles({ height });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
      {...others}
    >
      {children}
    </Dialog>
  );
}
