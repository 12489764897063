import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "components/Fields/TextField";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { loginViews } from "pages/Login";
import AuthenticateService from "services/authenticate";

const validationSchema = yup.object({
  document: yup.string().required().length(11, "CPF deve ter 11 digitos"),
  email: yup.string().required(""),
});

function ForgetPassword({ handleChangeView, handleBackView, handleChangeEmail }) {
  const classes = useStyles();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, control, reset, watch, setValue, setError, clearErrors } = useForm({
    resolver,
    defaultValues: { email: "go********rto@email.com" },
  });
  const document = watch("document");

  useEffect(() => {
    setValue("email", "");
    clearErrors("document");
    const fetchEmail = async () => {
      try {
        const { email } = await AuthenticateService.getEmail(document);
        setValue("email", email);
      } catch {
        setError("document", { message: "CPF não encontrado" });
      }
    };
    if (document?.length === 11) fetchEmail();
  }, [document, clearErrors, setValue, setError]);

  const onSubmit = useCallback(async (data) => {
    try {
      await AuthenticateService.sendRecoveryPassword(data.document)
      handleChangeEmail(data.email);
      handleChangeView(loginViews.SENDED_PASSWORD);
      reset();
    } catch (err) {
      console.log(err);
    }
  }, [reset, handleChangeView, handleChangeEmail]);

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Grid item>
          <Grid container alignItems="stretch">
            <Grid item xs={12}>
              <TextField
                control={control}
                name="document"
                label="CPF"
                mask="cpf"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" color="textSecondary" variant="body2">
                Enviar para:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                control={control}
                name="email"
                type="email"
                label="E-mail"
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
          >
            enviar e-email
          </Button>
          <Button
            variant="text"
            color="primary"
            disableTouchRipple
            className={classes.backBtn}
            size="large"
            onClick={handleBackView}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default ForgetPassword;
