import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import useStyles from "./styles";

/* import Button from '@material-ui/core/Button'; */
import { EventEmitter } from "services/emitter";

const AppBar = ({ title, secondaryAction }) => {
  const classes = useStyles();

  const onShowMenu = () => {
    EventEmitter.emit("menu-toggle");
  };

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <Typography
          variant="subtitle1"
          align="center"
          className={classes.title}
        >
          {title || ""}
        </Typography>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <IconButton
              onClick={onShowMenu}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item>{secondaryAction}</Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
