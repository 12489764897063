import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { Box } from '@material-ui/core';
import ServiceService from 'services/models/service';

function SearchInput({ onChangeData }) {
  const [term, setTerm] = useState();
  const [loading, setLoading] = useState(false);

  /* Fetching with cancel prev fetch if changed the input value */
  useEffect(() => {
    let active = true;
    let controller = new AbortController();
    let { signal } = controller;
    
    const fetchData = async () => {
      controller.abort();
      controller = new AbortController();
      signal = controller.signal;
      setLoading(true);
      try {
        const { result: { list: data } } = await ServiceService.list({ filter: term }, signal);
        if (active) {
          onChangeData(data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }

    fetchData();

    return () => {
      active = false;
      controller.abort();
    };
  }, [term, onChangeData]);

  const handleChange = (e) => {
    const { value } = e.target;
    setTerm(value);
  };

  return (
    <TextField
      label="Busca"
      variant="outlined"
      fullWidth
      value={term}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <Box color="action.disabled" display="flex" gridGap={8}>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            <SearchIcon color="inherit" size={20} /> 
          </Box>
        ),
      }}
    />
  );
}

export default SearchInput;