import React from "react";

import MuiTimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ResumedItemDescription from "../ResumedItemDescription";
import useStyles from "./styles";

function TimelineItem({
  onClickInfo,
  showInfoBtn = true,
  ...historyItem
}) {
  const classes = useStyles();

  return (
    <MuiTimelineItem className={classes.item}>
      <TimelineSeparator>
        <TimelineDot className={classes.dotSeparator}>
          <AssessmentOutlinedIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ResumedItemDescription
          {...historyItem}
          onClickInfo={onClickInfo}
          showInfoBtn={showInfoBtn}
        />
      </TimelineContent>
    </MuiTimelineItem>
  );
}

export default TimelineItem;