import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  sticky: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.common.white
  }
}));

export default useStyles;