import React from "react";

import { Router } from "@reach/router";
import useAuthenticate from "hooks/useAuthenticate";

import PublicRoutes from "./public";
import ProtectedRoutes from "./protected";

import IntroPage from "pages/Intro";
import RequestServicePage from "pages/RequestService/New";
import ListServicePage from "pages/RequestService/List";
import HomePage from "pages/Home";
import LoginPage from "pages/Login";
import SignupPage from "pages/Signup";
import ReviewRequestPage from "pages/RequestService/Review";
import RequestPage from "pages/RequestService/Details";
import ProfilePage from "pages/Profile";

const NotFound = () => <div>Sorry, nothing here.</div>;

export default function AppRouter() {
  const { isLogged } = useAuthenticate();

  return (
    <Router>
      <PublicRoutes path="/">
        <LoginPage path="/login" />
        <SignupPage path="/signup" />
        <IntroPage path="/intro" default />
      </PublicRoutes>

      <ProtectedRoutes isLogged={isLogged} path="/s">
        <HomePage path="/inicio" />
        <RequestServicePage path="/solicitar-um-servico" />
        <ListServicePage path="/minhas-solicitacoes" />
        <RequestPage path="/solicitacao/:id" />
        <ReviewRequestPage path="/avaliar-solicitacao/:id" />
        <ProfilePage path="/perfil" />
      </ProtectedRoutes>

      <NotFound default />
    </Router>
  );
}
