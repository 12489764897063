import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import useAuthenticate from "hooks/useAuthenticate";
import UserService from "services/models/user";


const Profile = ({ onClick, lastServiceRequest }) => {
  const classes = useStyles();
  const { userLogged } = useAuthenticate();
  const [user,setUser] = useState(userLogged);
  
  useEffect(()=> {
    const fetchUser = async () => {
      const user = await UserService.findById(userLogged._id);
      if(user) setUser(user);
    }
    fetchUser();
  }, [])

  const formatDate = (createdAt) => new Date(createdAt).toLocaleDateString();

  return (
    <ListItem className={classes.li} onClick={onClick} button>
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={user?.avatar || userLogged?.avatar}>
          <PersonIcon color="secondary" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={userLogged?.name || ""}
        secondary={lastServiceRequest && `Última solicitação feita em: ${formatDate(lastServiceRequest.createdAt)}`}
        secondaryTypographyProps={{ color: "inherit" }}
      />
    </ListItem>
  );
};

export default Profile;
