import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '88vh',
    width: '100%',
    margin: 0
  },
  grid: {
    margin: 0, 
    width: '100%'
  },
  backBtn: {
    marginTop: theme.spacing(2)
  }
}));

export default useStyles;