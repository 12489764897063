import React from 'react';
import { ReactComponent as CreateServiceSvg } from 'assets/images/tutorial/create-service.svg';
import { ReactComponent as AnalyzeServiceSvg } from 'assets/images/tutorial/analyze-service.svg';
import { ReactComponent as ExecuteServiceSvg } from 'assets/images/tutorial/execute-task.svg';
import { ReactComponent as NotifyAllSvg } from 'assets/images/tutorial/notify-all.svg';
import Carousel from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import Card from '../CardMedia';

const steps = [
  {
    title: "Criar serviço",
    image: <CreateServiceSvg />,
    description: "Texto dizendo que o cidadão tem que perceber algum serviço na cidade que precisa ser feito e enviar essa demanda pelo app clicando no botão abaixo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id arcu lectus. Pellentesque a erat lectus. Sed ullamcorper, nisi pulvinar pellentesque finibus, nulla nisl congue lorem, quis commodo odio ipsum nec enim."
  },
  {
    title: "Analisar serviço",
    image: <AnalyzeServiceSvg />,
    description: "Nesta etapa a prefeitura analisa o serviço e aceita realiza-lo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id arcu lectus. Pellentesque a erat lectus. Sed ullamcorper, nisi pulvinar pellentesque finibus, nulla nisl congue lorem, quis commodo odio ipsum nec enim."
  },
  {
    title: "Executar demanda",
    image: <ExecuteServiceSvg />,
    description: "Nesta etapa a empresa que a prefeitura contratou realiza o serviço. In id arcu lectus. Pellentesque a erat lectus. Sed ullamcorper, nisi pulvinar pellentesque finibus, nulla nisl congue lorem, quis commodo odio ipsum nec enim."
  },
  {
    title: "Notificar todos",
    image: <NotifyAllSvg />,
    description: "Nesta etapa a empresa que a prefeitura contratou realiza o serviço. In id arcu lectus. Pellentesque a erat lectus. Sed ullamcorper, nisi pulvinar pellentesque finibus, nulla nisl congue lorem, quis commodo odio ipsum nec enim."
  }
];

const TutorialSlide = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <>
      <Carousel index={activeStep} onChangeIndex={(i)=> setActiveStep(i)}>
        {steps.map(step => <Card key={`card-${step.title}`} data={step}/>)}
      </Carousel>
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
      />
    </>
  )
};

export default TutorialSlide;