import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { loginViews } from "pages/Login";
import React from "react";
import useStyles from "./styles";

function SendedPassword({ handleChangeView, email }) {
  const classes = useStyles();

  const handleBackView = () => {
    handleChangeView(loginViews.LOGIN);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      className={classes.root}
      spacing={3}
    >
      <Grid item xs={12}>
        <Typography align="left" color="textSecondary" variant="body2">
          O e-mail foi enviado para o recipiente de endereço{" "}
          {email}. Verifique sua caixa de mensagens e siga
          as instruções para redefinir a sua senha e então, clique no botão
          abaixo para realizar o login.
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Button
          variant="contained"
          color="primary"
          disableTouchRipple
          size="large"
          onClick={handleBackView}
          fullWidth
        >
          voltar para o login
        </Button>
      </Grid>
    </Grid>
  );
}

export default SendedPassword;
