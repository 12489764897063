import React, { memo, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useStyles from "./styles";
import { serviceViews } from "pages/RequestService/New";
import { useForm } from "react-hook-form";
import TextField from "components/Fields/TextField";
import Typography from "@material-ui/core/Typography";
import ImageField from "components/Fields/ImageField";
import LocationInfo from "./LocationInfo";
import ServiceRequestService from "services/models/serviceRequest";

const DetailsService = memo((props) => {
  const { dispatch, handleChangeView, handleBackView, store } = props;
  const classes = useStyles();
  const { handleSubmit, register, setValue, control, watch } = useForm();

  useEffect(() => {
    const serviceName = store.service;
    register("service", serviceName);
    setValue("service", serviceName);
  }, [store.service, register, setValue]);

  const handleNextPage = () => {
    handleChangeView(serviceViews.COMPLETED);
  };

  const handleUpdateLocation = () => {
    handleChangeView(serviceViews.LOCATION);
  };

  const onSubmit = async ({ service, ...data }) => {
    try {
      const result = await ServiceRequestService.create({ ...store, ...data });
      dispatch({...data, ...result});
      handleNextPage();
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="h6"
              >
                SERVIÇO:
              </Typography>
              <Typography
                color="secondary"
                variant="h5"
                align="center"
                gutterBottom
              >
                {watch("service.name")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" variant="body1" gutterBottom>
                Agora precisamos que você preencha os campos abaixo.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocationInfo
                location={store.location}
                handleUpdateLocation={handleUpdateLocation}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                control={control}
                fullWidth
                label="Descrição"
                name="description"
                required
                multiline
                minRows={5}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageField
                control={control}
                label="Fotos"
                name="images"
                maxLength={3}
                accept="image/*"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
          >
            Finalizar
          </Button>
          <Button
            color="primary"
            variant="text"
            fullWidth
            onClick={handleBackView}
            className={classes.backBtn}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

export default DetailsService;
