import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  grid: {
    minHeight: '88vh'
  },
  backBtn: {
    marginTop: theme.spacing(2)
  },
  rating: {
    fontSize: '2.875rem !important',
    justifyContent: 'center !important',
    display: 'flex !important'
  },
  emojiRating: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    color: '#A2D8BA'
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));

export default useStyles;