import React, { useCallback, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import useStyles from "./styles";
import ListItem from "./ListItem";
import Filter from "./Filter";
import ServiceRequestService from "services/models/serviceRequest";

export const columns = [
  {
    id: "createdAt",
    label: "Data",
    format: (value) => new Date(value).toLocaleDateString("pt-BR"),
  },
  {
    id: "service",
    label: "Serviço",
    format: (value) => value?.name,
  },
  {
    id: "status",
    label: "Status",
    format: (value) => value?.public,
  },
  {
    id: "code",
    label: "Protocolo",
  },
];

function ServiceList(props) {
  const { onToggleFilter, openFilterDrawer, filterLocation } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({ ...filterLocation });
  const [params, setParams] = useState({ page, filter });

  useEffect(() => {
    const fetch = async () => {
      const { list, countQuery } = await ServiceRequestService.list(params);
      setTotal(countQuery);
      setData(list);
    };
    fetch();
  }, [params]);

  useEffect(() => {
    if (!openFilterDrawer) {
      setParams((p) => ({
        ...p,
        filter: {
          ...filter,
          status: filter.status?.length ? filter.status : undefined
        },
        page: 0,
      }));
    }
  }, [openFilterDrawer]);

  const handleChangeFilter = useCallback(
    (status) => () => {
      if (filter.status?.includes(status)) {
        setPage(0);
        setFilter({
          ...filter,
          status: filter.status.filter((i) => i !== status),
        });
      } else
        setFilter((s) => ({ ...s, status: [...(s.status || []), status] }));
    },
    [filter]
  );

  const handleChangePage = (event, page) => {
    setPage(page);
    setParams((p) => ({ ...p, page }));
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="right" className={classes.sticky}>
                Ação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <ListItem key={row._id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        labelRowsPerPage={null}
        labelDisplayedRows={({ to, count, from }) =>
          `${from} - ${to} de ${count}`
        }
        rowsPerPageOptions={[]}
        count={total}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
      <Filter
        filter={filter}
        onChangeFilter={handleChangeFilter}
        onToggleFilter={onToggleFilter}
        open={openFilterDrawer}
      />
    </>
  );
}

export default ServiceList;
