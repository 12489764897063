import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import useStyles from "./styles";
import { formatLocationLabel } from 'utils/formatLocationLabel';

function LocationInfo(props) {
  const { location, handleUpdateLocation } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.root}>
      <Grid item>
        <Typography align="center" color="primary">
          Localização
        </Typography>
      </Grid>
      <Grid item>
        <Button startIcon={<EditIcon />} variant="text" color="secondary" onClick={handleUpdateLocation}>
          alterar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" color="primary" className={classes.location}>
          <LocationOnOutlinedIcon fontSize="small" color="disabled" /> { formatLocationLabel(location?.display_name)}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default LocationInfo;
