export const cpf =  {
  masked(value){
    if(!value) return '';
    return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  },
  clean(value){
    if(!value) return '';
    return value.replace(/\D/gi, '').substr(0, 11);
  }
}

