import React, { createContext, useState, useCallback, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { EventEmitter } from "services/emitter";

const LOCALSTORAGE_KEY = "auth_token";

export const AuthContext = createContext({
  isLogged: false,
  token: localStorage.getItem(LOCALSTORAGE_KEY),
  userLogged: null,
  login: () => {},
  logout: () => {},
});

function AuthContextProvider(props) {
  const { children } = props;
  const [token, setToken] = useState(localStorage.getItem(LOCALSTORAGE_KEY));
  const [userLogged, setUserLogged] = useState(token && jwtDecode(token));

  const login = useCallback((tokenArg) => {
    try {
      const user = jwtDecode(tokenArg);
      localStorage.setItem(LOCALSTORAGE_KEY, tokenArg);
      setToken(tokenArg);
      setUserLogged(user);
    } catch (err) {
      return;
    }
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem(LOCALSTORAGE_KEY);
  }, [setToken]);

  const changeProfile = useCallback((user) => {
    setUserLogged((d) => ({...d, ...user}));
  }, []);

  useEffect(() => {
    EventEmitter.on("login-change", login);
    EventEmitter.on("logout", logout);
    return () => {
      EventEmitter.off("login-change", login);
      EventEmitter.off("logout", logout);
    };
  }, [token, logout]);


  return <AuthContext.Provider value={{ login, logout, token, userLogged, isLogged: !!token, changeProfile }}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
