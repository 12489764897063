import { makeStyles } from "@material-ui/styles";
import { green, red } from "@material-ui/core/colors";
import { alpha } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  textDescription: {
    textIndent: "1em",
    fontSize: ".7rem",
    letterSpacing: ".07rem",
    lineHeight: "1rem",
    color: alpha(theme.palette.text.secondary, 0.7),
  },
  authorText: {
    fontSize: ".7rem",
    lineHeight: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.secondary,
    textIdent: "1em",
    textAlign: "justify",
  },
  iconButton: {
    padding: theme.spacing(0, 1),
  },
  arrow: {
    fontSize: "2.3rem",
    top: "2px",
    left: -33,
    position: "absolute",
    color: green[200],
  },
  link: {
    textDecoration: "underline",
    fontSize: ".7rem",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    letterSpacing: ".09em",
  },
}));

export default useStyles;