import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "components/Fields/TextField";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useStyles from "./styles";
import * as yup from "yup";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import Typography from "@material-ui/core/Typography";
import { navigate } from "@reach/router";
import AuthenticateService from "services/authenticate";

const validationSchema = yup.object({
  document: yup.string().required().length(11, 'CPF deve ter 11 digitos'),
  password: yup.string()
  .required().min(8),
  confirmPassword: yup.string()
  .oneOf([yup.ref('password'), null], "Senhas não coincidem")
  .required()
});


function SetPassword({ handleBackView, store }) {
  const classes = useStyles();
  const resolver = useYupValidationResolver(validationSchema);
  const { handleSubmit, control, setValue } = useForm({
    resolver, 
    defaultValues: { document: store.document } 
  });

  useEffect(()=> {
    setValue('document', store.document);
  }, [store.document, setValue]);

  const onSubmit = async ({ confirmPassword, ...data }) => {
    try {
      await AuthenticateService.register({ ...data, ...store })
      navigate('/login');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <TextField
            control={control}
            name="document"
            label="CPF"
            mask="cpf"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="password"
            type="password"
            label="Senha"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' color='textSecondary' align='left' gutterBottom>
            Preencha este campo com uma senha de no{' '} 
            mínimo 8 dígitos.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="confirmPassword"
            type="password"
            label="Confirmar senha"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' color='textSecondary' align='left' gutterBottom>
            Insira novamente a sua senha para confirma-la.
          </Typography>
        </Grid>
        <Grid item xs={7} className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
          >
            Finalizar
          </Button>
          <Button
            variant="text"
            color="primary"
            disableTouchRipple
            className={classes.backBtn}
            size="large"
            onClick={handleBackView}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SetPassword;
