import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  status: {
    paddingTop: theme.spacing(1),
    fontWeight: 800,
    fontSize: ".6rem",
    textTransform: "uppercase",
    letterSpacing: ".53px",
  },
  iconButton: {
    marginLeft: "auto",
  },
}));

export default useStyles;
