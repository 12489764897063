import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AppBar from "components/AppBar";
import Carousel from "react-swipeable-views";
import useStyles from "./styles";
import Login from "components/LoginViews/Login";
import ForgetPassword from "components/LoginViews/ForgetPassword";
import SendedPassword from "components/LoginViews/SendedPassword";

export const loginViews = {
  LOGIN: 0,
  FORGET_PASSWORD: 1,
  SENDED_PASSWORD: 2,
};

const LoginPage = ({ location }) => {
  const classes = useStyles();
  const [currentView, seCurrentView] = useState(loginViews.LOGIN);
  const [email, setEmail] = useState(loginViews.LOGIN);

  const handleBackView = () => {
    if (currentView > 0) seCurrentView(currentView - 1);
  };

  return (
    <>
      <AppBar title="Solicitar um serviço" />
      <Container className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          align="center"
          direction="column"
          spacing={2}
        >
          <Grid item xs="auto">
            <Typography
              gutterBottom
              variant="h6"
              align="center"
              color="secondary"
            >
              Realizar login
            </Typography>
            <Typography gutterBottom color="primary" align="left">
              Para solicitar um novo serviço é necessário que você preencha os
              campos abaixo.
            </Typography>
          </Grid>
          <Grid item xs>
            <Carousel index={currentView} disabled>
              <Login
                handleChangeView={seCurrentView}
                locationState={location.state}
              />
              <ForgetPassword
                handleChangeView={seCurrentView}
                handleChangeEmail={setEmail}
                handleBackView={handleBackView}
              />
              <SendedPassword handleChangeView={seCurrentView} email={email}/>
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LoginPage;
