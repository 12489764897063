import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rating: {
    fontSize: '2.875rem !important',
    justifyContent: 'center !important',
    display: 'flex !important'
  }
}));

export default useStyles;