import { makeStyles } from "@material-ui/styles";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  item: {
    "&.MuiTimelineItem-missingOppositeContent:before": {
      display: "none",
    },
  },
  dotSeparator: {
    backgroundColor: green[200],
  },
}));

export default useStyles;
