import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "components/AppBar";
import useStyles from "./styles";
import { navigate } from "@reach/router";
import HouseIcon from "@material-ui/icons/House";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import EditField from "components/Profile/EditField";
import { useForm } from "react-hook-form";
import TextField from "components/Fields/TextField";
import { fields } from "utils/userFields";
import ButtonLogout from "components/Profile/ButtonLogout";
import useAuthenticate from "hooks/useAuthenticate";
import UserService from "services/models/user";
import { formatToBase64 } from "utils/formatToBase64";

const ProfilePage = () => {
  const classes = useStyles();
  const { userLogged } = useAuthenticate();
  const [openEditFieldDrawer, setOpenEditFieldDrawer] = useState(false);
  const [currentField, setCurrentField] = useState();
  const [profileAvatar, setProfileAvatar] = useState(userLogged?.avatar);
  const avatarRef = useRef(null);
  const { control, reset, getValues } = useForm({
    defaultValues: {
      cpf: "",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      birthday: "",
      city: "",
      neighborhood: "",
    },
  });

  const fetchUser = async () => {
    const user = await UserService.findById(userLogged._id)
    if(user){
      setProfileAvatar(user.avatar);
      const fullName = user.name?.split(' ') || [];
      const name = fullName[0];
      const lastName = fullName.slice(1).join(' ')
      reset({
        ...user,
        birthdayText: user?.birthday && new Date(user.birthday).toLocaleDateString('pt-BR'),
        name,
        lastName
      });
    }
  }

  useEffect(()=> {
    fetchUser();
  }, [])

  const updateUser = async () => {
    const data = getValues();
    await UserService.save({
      ...data, _id: userLogged._id,
      name: `${data.name} ${data.lastName || ""}`.trim(),
      avatar: undefined
    });
    fetchUser();
  }

  const handleToggleFilter = (open) => {
    setOpenEditFieldDrawer(open);
  };

  const handleEditField = (field) => () => {
    setCurrentField(field);
    handleToggleFilter(true);
  };

  const handleNavigate = async () => {
    await navigate(`/s/inicio`);
  };

  const onFileSelected = async () => {
    const { files: [file] } = avatarRef?.current;
    if(file) {
      const base64 = await formatToBase64(file);
      await UserService.updateAvatar(file);
      fetchUser();
      setProfileAvatar(base64);
    }
  };

  const handleChangeAvatar = () => {
    avatarRef.current.click();
  }

  return (
    <>
      <AppBar title="Perfil" secondaryAction={<ButtonLogout/>}/>
      <input
        type="file"
        ref={avatarRef}
        style={{ display: "none" }}
        onChange={onFileSelected}
        accept="image/*"
        capture
        multiple
      />
      <div className={classes.avatarContainer}>
        <Avatar variant="square" classes={{ root: classes.avatar, img: classes.avatarImg }} src={profileAvatar || userLogged?.avatar} />
        <IconButton className={classes.addBtn} size="medium" onClick={handleChangeAvatar}>
          <AddAPhotoIcon fontSize="small" />
        </IconButton>
      </div>
      <Container className={classes.root}>
        <Grid container justifyContent="center">
          {fields.map((field) => (
            <Grid item key={`${field.name}-field`} xs={12}>
              <TextField
                {...field}
                name={field.nameText || field.name}
                variant="standard"
                control={control}
                disabled={field.disabled}
                InputProps={{
                  readOnly: field.disabled,
                  endAdornment: !field.disabled && (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <Chip
                        size="small"
                        color="secondary"
                        variant="outlined"
                        icon={<EditIcon />}
                        onClick={handleEditField(field)}
                        className={classes.chip}
                        label="Alterar"
                      />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          ))}
          <Grid item xs={8} sm={6} md={4}>
            <Button
              color="primary"
              variant="text"
              startIcon={<HouseIcon />}
              onClick={handleNavigate}
              className={classes.homeBtn}
              fullWidth
            >
              Voltar ao início
            </Button>
          </Grid>
        </Grid>
      </Container>
      <EditField
        handleToggleEditField={handleToggleFilter}
        openEditFieldDrawer={openEditFieldDrawer}
        control={control}
        updateUser={updateUser}
        field={currentField}
      />
    </>
  );
};

export default ProfilePage;
