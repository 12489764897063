import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CardMedia from "@material-ui/core/CardMedia";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles, { ButtonRemove } from "./styles";

export default function PreviewPhoto(props) {
  const { open, onClose, current, onRemovePhoto, onExited, disabled } = props;
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} onExited={onExited}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" className={classes.filename}>{typeof current === "string" ? current : current?.file?.name}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CardMedia
          component="img"
          alt={typeof current === "string" ? current : current?.file?.name}
          title={typeof current === "string" ? current : current?.file?.name}
          height="100%"
          draggable={false}
          image={typeof current === "string" ? current : current?.base64}
        />
      </DialogContent>
      {!disabled && (
        <DialogActions className={classes.actions}>
          <ButtonRemove
            onClick={onRemovePhoto}
            startIcon={<DeleteIcon />}
            color="primary"
            variant="contained"
          >
            Remover
          </ButtonRemove>
        </DialogActions>
      )}
    </Dialog>
  );
}
