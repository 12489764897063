const overrides = {
  MuiMobileStepper: {
    root: {
      justifyContent: 'center'
    }
  },
  MuiButton: {
    root: {
      borderRadius: 32
    }
  },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: 'rgb(2,75,35)',
      background: `linear-gradient(90deg, rgba(2,75,35,1) 0%, rgba(11,111,56,1) 100%)`
    }
  },
  MuiCardActions: {
    root: {
      flexDirection: 'column'
    },
    spacing: {
      gap: 8
    }
  },
  MuiTableCell: {
    root: {
      padding: 8
    }
  }
};

export default overrides;
