import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "components/AppBar";
import useStyles from "./styles";
import { navigate } from "@reach/router";
import FilterListIcon from "@material-ui/icons/FilterList";
import ServiceList from "components/ServiceList";

function ListserviceViews({ location }) {
  const classes = useStyles();
  const { filter } = location.state || {};
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const handleExit = async () => {
    await navigate("/s/inicio");
  };


  return (
    <>
      <AppBar
        title="Minhas solicitações"
        secondaryAction={
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpenFilterDrawer(true)}>
            <FilterListIcon />
          </IconButton>
        }
      />
      <Grid
        container
        className={classes.root}
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <ServiceList filterLocation={filter} onToggleFilter={setOpenFilterDrawer} openFilterDrawer={openFilterDrawer}/>
        </Grid>
        <Grid item>
          <Button variant="text" color="primary" onClick={handleExit}>
            Voltar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default ListserviceViews;
