import Timeline from "@material-ui/lab/Timeline";
import React, { useCallback, useState } from "react";
import { useDisclosure } from "hooks/useDisclosure";
import TimelineItem from "./TimelineItem";
import HistoryDetails from "./Details";
import useStyles from "./styles";

function History({ demand }) {
  const classes = useStyles();
  const [selectedHistory, setSelectedHistory] = useState();

  const {
    isOpen: isModalOpen,
    open: handleOpenModal,
    close: handleCloseModal,
  } = useDisclosure(false);

  const handleClickInfo = useCallback(
    (historyItem) => {
      setSelectedHistory(historyItem);
      handleOpenModal();
    },
    [handleOpenModal]
  );

  const getFooterInfo = useCallback(
    (history) =>
      history.attachments.length
        ? `Adicionou ${history.attachments.length} fotos`
        : "Não adicionou fotos",
    []
  );

  return (
    <>
      <Timeline className={classes.timeline}>
        {(demand.history || []).map((history, index) => (
            <TimelineItem
              onClickInfo={() => handleClickInfo(history)}
              type={history.type}
              status={history.status}
              message={history.message}
              createdAt={history.createdAt}
              key={`item-line-${index}`}
              requester={history.user?.name}
              footerInfo={getFooterInfo(history)}
            />
          )
        )}
      </Timeline>
      {selectedHistory && (
        <HistoryDetails
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          history={selectedHistory}
        />
      )}
    </>
  );
}

export default History;