import React, { useState } from "react";
import SearchInput from "./SearchInput";
import GridService from "./GridServices";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useStyles from "./styles";
import { serviceViews } from "pages/RequestService/New";
import Typography from "@material-ui/core/Typography";

const SelectService = (props) => {
  const { dispatch, handleChangeView, handleBackView, store } = props;
  const classes = useStyles();
  const [serviceData, setServiceData] = useState([]);

  const handleNextPage = () => {
    handleChangeView(serviceViews.DETAILS);
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12}>
            <Typography color="primary" variant="body2">
              Para solicitar um novo serviço é necessário que você selecione um
              tipo de serviço abaixo.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SearchInput onChangeData={setServiceData} />
          </Grid>
          <Grid item xs={12}>
            <GridService
              data={serviceData}
              dispatch={dispatch}
              value={store.service?._id}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          disabled={!store.service?._id}
          onClick={handleNextPage}
          fullWidth
        >
          Continuar
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={handleBackView}
          fullWidth
          className={classes.backBtn}
        >
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
};

export default SelectService;
