import React, { useCallback } from "react";
import DrawerBottom from "components/DrawerBottom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import useStyles from "./styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "components/Fields/TextField";
import DateField from "components/Fields/DateField";

function EditField(props) {
  const { handleToggleEditField, openEditFieldDrawer, control, field, updateUser } = props;
  const classes = useStyles();

  const handleBackView = () => {
    handleToggleEditField(false);
  };

  const handleEditField = useCallback(async () => {
    await updateUser();
    handleToggleEditField(false);
  }, [handleToggleEditField, updateUser]);

  return (
    <DrawerBottom
      open={openEditFieldDrawer}
      onToggleDrawer={handleToggleEditField}
    >
      <Container>
        <CardContent className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="center"
                className={classes.title}
                gutterBottom
              >
                Alterar {field?.label}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!field?.fieldType && <TextField {...field} control={control} fullWidth />}
              {field?.fieldType === 'date' && <DateField {...field} control={control} fullWidth />}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleEditField}
                fullWidth
              >
                Salvar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="text"
                onClick={handleBackView}
                fullWidth
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Container>
    </DrawerBottom>
  );
}

export default EditField;
