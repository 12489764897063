import React, { useCallback, useState } from "react";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AppBar from "components/AppBar";
import Carousel from "react-swipeable-views";
import SelectService from "components/ServiceForm/Select";
import DetailsService from "components/ServiceForm/Details";
import LocationService from "components/ServiceForm/Location";
import CompletedService from "components/ServiceForm/Completed";

export const serviceViews = {
  LOCATION: 0,
  SELECTION: 1,
  DETAILS: 2,
  COMPLETED: 3,
};

const Service = () => {
  const classes = useStyles();
  const [currentView, seCurrentView] = useState(serviceViews.LOCATION);
  const [store, setStore] = useState({});

  const handleBackView = () => {
    if (currentView > 0) seCurrentView(currentView - 1);
  };

  const dispatch = useCallback((data) => {
    setStore((store) => ({ ...store, ...data }));
  }, []);

  return (
    <>
      <AppBar title="Solicitar um serviço" />
      <div className={classes.root}>
        <Container>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Carousel index={currentView} disabled animateHeight>
                <LocationService
                  dispatch={dispatch}
                  handleChangeView={seCurrentView}
                  store={store}
                />
                <SelectService
                  dispatch={dispatch}
                  handleChangeView={seCurrentView}
                  handleBackView={handleBackView}
                  store={store}
                />
                <DetailsService
                  dispatch={dispatch}
                  handleChangeView={seCurrentView}
                  handleBackView={handleBackView}
                  store={store}
                />
                <CompletedService
                  store={store}
                />
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Service;
