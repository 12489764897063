import { Box } from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import { styled, withStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    position: "relative",
    overflow: "hidden",
  },
  menu: {
    padding: 0,
    color: theme.palette.common.white,
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default useStyles;

export const Button = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.spacing(2),
  },
  label: {
    flexDirection: "column",
  },
  startIcon: {
    margin: 0,
  },
  iconSizeLarge: {
    "& > *:first-child": {
      fontSize: theme.spacing(5),
    },
  },
  outlinedSecondary: {
    borderWidth: 3,
  },
}))(MuiButton);

export const BgColor = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  position: "absolute",
  top: 0,
  right: "-20vw",
  left: "-20vw",
  height: "32vh",
  width: "140vw",
  zIndex: "-1",
  borderBottomLeftRadius: "100%",
  borderBottomRightRadius: "100%",
}));

export const MenuBgColor = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: 0,
  position: "absolute",
  top: -35,
  right: -30,
  height: 138,
  width: 138,
  zIndex: "-1",
  borderTopLeftRadius: "50%",
  borderTopRightRadius: "50%",
  borderBottomLeftRadius: "50%",
  borderBottomRightRadius: "50%",
  [theme.breakpoints.up('sm')]: {
    height: 160,
    width: 160,
  },
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));
