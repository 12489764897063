import queryString from 'query-string';
const ACCESS_TOKEN_MAP_BOX = `pk.e3e17035538998558199f9518a65cf18`;
// const ACCESS_TOKEN_MAP_BOX = `9b0295b11cd18ee56c540d2c874e4825`;

export default class MapService {
  static base = `https://us1.locationiq.com/v1`;
  // static base = `http://api.positionstack.com/v1`;

  static async fetch(url, query = {}, params = {}) {
    // const _query = queryString.stringify({
    //   ...query,
    //   access_key: ACCESS_TOKEN_MAP_BOX,
    //   country: 'BR'
    // });

    const _query = queryString.stringify({
      ...query,
      key: ACCESS_TOKEN_MAP_BOX,
      format: "json"
    });

    const response = await fetch(`${this.base}${url}?${_query}`, {
      ...params
    });

    const result = await response.json();
    // return result.data;
    return result;
  }

  static async searchPlace(filter = '', signal) {
    // const query = {
    //   query: filter
    // };
    const query = {
      q: filter.slice(0,40),
      viewbox: "-40.50516502583202,-20.41498100585698,-40.36850199242774,-20.33407975382201",
      bounded: true
    };
    const response = await this.fetch('/autocomplete.php', query, signal);
    return response;
  }

  static async searchPlaceByLatLng(latitude, longitude) {
    const query = {
      lat: latitude,
      lon: longitude
    };
    const response = await this.fetch('/reverse.php', query);
    return response;
  }
}
