const palette = {
  type: 'light',
  primary: {
    light: '#A1FFCB',
    main: '#024B23',
    dark: '#024B23',
    contrastText: '#fff'
  },
  secondary: {
    light: '#A1FFCB',
    main: '#13AC58',
    dark: '#13AC58',
    contrastText: '#fff'
  }
}

export default palette;